var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _a, _SubSystem_hotReload;
import { interpolate, isPromise, upgradeElements } from './Common';
import { set } from 'lodash-es';
// eslint-disable no-unused-vars
var State;
(function (State) {
    State[State["STOPPED"] = 0] = "STOPPED";
    State[State["WAITING"] = 1] = "WAITING";
    State[State["STARTING"] = 2] = "STARTING";
    State[State["STARTED"] = 3] = "STARTED";
    State[State["NEW"] = 4] = "NEW";
})(State || (State = {}));
class SubSysBuilder {
    constructor(name, protoOrApi, revision) {
        this.sys = SubSystem.get(name);
        if (this.sys.data)
            revision = Math.max(this.sys.data.revision + 1, revision);
        this.sys._newData = this.data = {
            revision,
            deps: new Set(),
            api: undefined,
            proto: undefined,
            stop: undefined,
            start: undefined,
            state: State.STOPPED,
            customElements: [],
            reloadable: true,
        };
        if (typeof protoOrApi === 'function') {
            this.data.proto = protoOrApi;
        }
        else if (typeof protoOrApi === 'object') {
            this.data.api = protoOrApi;
        }
    }
    depends(...deps) {
        for (const d of deps) {
            this.data.deps.add(SubSystem.get(typeof d === 'string' ? d : d._id));
        }
        return this;
    }
    stop(handler) {
        this.data.stop = handler;
        return this;
    }
    start(handler) {
        this.data.start = handler;
        return this;
    }
    prototype(proto) {
        this.data.proto = proto;
        return this;
    }
    api(api) {
        this.data.api = api;
        api['_id'] = this.sys.name;
        api['_revision'] = this.data.revision;
        return this;
    }
    reloadable(reloadable) {
        this.data.reloadable = reloadable;
        return this;
    }
    register() {
        SubSystem.register(this.sys.name, this.data);
        const api = this.sys.proxy || this.data.api;
        return api;
    }
    elements(...customElements) {
        this.data.customElements.push(...customElements);
        return this;
    }
}
function nop() {
    //
}
const AsyncFunction = async function () { }.constructor;
function proxy(target, dep) {
    let obj = typeof target === 'function' ? target.prototype : target;
    return new Proxy(obj, {
        get(target, p, receiver) {
            // get newest version
            /*
            if (SubSystem.hotReload) {
                const refreshed = dep.data.api;
                if (refreshed && refreshed !== obj) {
                    console.log('Subsystem refreshed: %s', dep.name, dep);
                    obj = refreshed;
                }
            }*/
            if (p === '_id')
                return dep.name;
            else if (p === '_revision')
                return dep.data.revision;
            else if (p === '_proxy')
                return true;
            else if (p === '_proxied')
                return obj;
            if (dep.data) {
                return dep.data.api[p];
            }
            else if (obj[p] instanceof AsyncFunction) {
                console.trace(`using proxy for ${dep.name}.${String(p)}`);
                // wrap it in an object to get correct name
                const funcObj = {
                    async [p](...args) {
                        await SubSystem.waitFor(dep.name);
                        const fn = dep.data?.api[p];
                        if (typeof fn === 'function' && !fn.proxy) {
                            console.trace(`calling proxy for ${name}.${String(p)}`);
                            return fn(args);
                        }
                        throw new TypeError(`${String(p)} is not a function`);
                    },
                };
                const f = funcObj[p]; // as (...args:any[]) => Promise<any>;
                f.proxy = true;
                return f;
            }
            else {
                console.error(`Accessing ${dep.name}.${String(p)} before ${dep.name} is ready`, target, p, receiver);
                throw new Error(`Accessing ${dep.name}.${String(p)} before ${dep.name} is ready`);
            }
        },
    });
}
export class SubSystem {
    static get hotReload() {
        return __classPrivateFieldGet(_a, _a, "f", _SubSystem_hotReload);
    }
    get api() {
        return this.data.api;
    }
    constructor(name) {
        this.name = name;
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
        console.log('Created', this.toString());
    }
    dependencies() {
        return new Set(this.data.deps);
    }
    dependents() {
        const result = new Set();
        _a.systems.forEach((sys) => {
            if (sys.data.deps.has(this)) {
                result.add(sys);
            }
        });
        return result;
    }
    forEach(callback) {
        this.data.deps.forEach(callback);
    }
    isStarted() {
        return this.data?.state === State.STARTED;
    }
    status() {
        return `${this.data.state}`;
    }
    get state() {
        return this.data?.state ?? this._newData?.state ?? State.NEW;
    }
    toString() {
        return `${this.name} (${State[this.state]})`;
    }
    static debugAll() {
        console.group('Subsystems:');
        _a.systems.forEach((dep) => dep.debug());
        console.groupEnd();
    }
    debug() {
        console.log(this.toString(), ':', [...(this.data?.deps ?? this._newData?.deps ?? [])].map((d) => d.name).join(', '));
    }
    static get(sysName) {
        let sys = _a.systems.get(sysName);
        if (!sys) {
            sys = new _a(sysName);
            if (_a.makeProxies)
                sys.proxy = proxy({}, sys);
            _a.systems.set(sysName, sys);
        }
        return sys;
    }
    static getApi(sysName) {
        const sys = _a.get(sysName);
        return sys.proxy || sys.data.api;
    }
    static setup(targetObject, config) {
        if (config?.global)
            globalThis.SubSystem = _self;
        _a.targetObject = targetObject;
        _a.makeProxies = !!config?.proxy;
        _a.alwaysProxy = config?.proxy === 'always';
        __classPrivateFieldSet(_a, _a, !!config?.hotReload, "f", _SubSystem_hotReload);
        _a.ready = true;
        if (targetObject) {
            set(targetObject, 'borb.systems', _self);
        }
        _a.setupResolve(targetObject);
    }
    static async waitFor(sysOrName) {
        const dep = _a.get(typeof sysOrName === 'string' ? sysOrName : sysOrName._id);
        console.log('waiting for', dep);
        await dep.promise;
        console.log('waited for', dep);
        return Promise.resolve(dep.api);
    }
    static async waitForAll(...sysOrNames) {
        const deps = sysOrNames.map((sysOrName) => _a.get(typeof sysOrName === 'string' ? sysOrName : sysOrName._id));
        console.log('waiting for', deps);
        await Promise.all(deps.map((d) => d.promise));
        console.log('waited for', deps);
    }
    static register(name, data) {
        const sys = _a.get(name);
        const old = sys.data;
        if (old && !old.reloadable) {
            throw new Error(`Already registered: ${sys.name}`);
        }
        data.state = State.WAITING;
        sys._newData = data;
        sys.setApi();
        console.log('Registered', sys.toString());
        const promises = [...data.deps].map((d) => d.promise);
        return Promise.all([_a.setupPromise, ...promises])
            .then((res) => sys.init(res))
            .then(() => queueMicrotask(() => sys.resolve(sys)));
    }
    setApi() {
        const data = this.data || this._newData;
        let api = data.api;
        if (_a.alwaysProxy || (_a.makeProxies && (!api || !this.data))) {
            api = this.proxy = proxy(data.api ?? data.proto ?? {}, this);
        }
        else {
            this.proxy = undefined;
        }
        if (api && _a.targetObject) {
            set(_a.targetObject, this.name.replace(/\//, '.'), api);
        }
    }
    async init(res) {
        console.log('init', this, res);
        const data = this._newData;
        const old = this.data;
        if (old && old.state !== State.STOPPED) {
            // stop old one
        }
        if (data.state !== State.STARTED && data.state !== State.STARTING) {
            try {
                this.data = this._newData;
                delete this._newData;
                data.state = State.STARTING;
                if (data.start) {
                    console.groupCollapsed(`Starting ${this.name} rev.${data.revision}`);
                    const res = data.start(this);
                    console.groupEnd();
                    if (isPromise(res)) {
                        console.log('Waiting for %s startup', this.name);
                        data.api = await res;
                    }
                    else {
                        data.api = res;
                    }
                }
                this.setApi();
                if (data.customElements && data.customElements.length > 0)
                    await this.defineCustomElements();
                data.state = State.STARTED;
                // console.groupEnd();
                console.log('Started', this.toString());
                return this;
            }
            catch (reason) {
                // console.groupEnd();
                console.error(`While starting ‘${this.name}’:`, reason);
                data.state = State.STOPPED;
                if (reason instanceof Error) {
                    reason.message = `In subsystem ‘${this.name}’: ${reason.message}`;
                    return Promise.reject(reason);
                }
                else {
                    return Promise.reject(new Error(`In subsystem ‘${this.name}’: ${reason}`));
                }
            }
        }
        else {
            console.warn('Already started', this);
            return this;
        }
    }
    async defineCustomElements() {
        console.groupCollapsed(`Defining ${this.name} custom elements`);
        this.data.customElements.forEach((eltDef) => {
            try {
                console.debug('defining custom element %s: %o', eltDef.tag, eltDef);
                customElements.define(eltDef.tag, eltDef);
                if (this.data.revision > 0)
                    upgradeElements(eltDef);
            }
            catch (e) {
                console.error(e);
            }
        });
        console.groupEnd();
    }
    static declare(nameOrObj, prototype, revision = 0) {
        if (typeof nameOrObj === 'string')
            return new SubSysBuilder(nameOrObj, prototype, revision);
        else if (nameOrObj._id)
            return new SubSysBuilder(nameOrObj._id, nameOrObj, nameOrObj._revision ?? revision);
    }
}
_a = SubSystem;
SubSystem.counter = 0;
SubSystem.systems = new Map();
SubSystem.queue = [];
SubSystem.makeProxies = true;
SubSystem.alwaysProxy = false;
SubSystem.ready = false;
SubSystem.setupPromise = new Promise((resolve) => {
    _a.setupResolve = resolve;
});
_SubSystem_hotReload = { value: false };
globalThis.addEventListener?.('DOMContentLoaded', (loadedEvent) => {
    SubSystem.declare('dom').register();
});
const _self = {
    debugAll: SubSystem.debugAll,
    register: SubSystem.register,
    get: SubSystem.get,
    getApi: SubSystem.getApi,
    setup: SubSystem.setup,
    waitFor: SubSystem.waitFor,
    waitForAll: SubSystem.waitForAll,
    declare: SubSystem.declare,
    util: {
        interpolate,
    },
    State,
};
export const Systems = _self;
export default Systems;
