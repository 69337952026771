import { isEqual } from "lodash-es";
export const tables = {};
function dateify(date) { if (date instanceof Date)
    return date.getTime();
else if (date)
    return new Date(date).getTime();
else
    return 0; }
export class _AuditLog {
    constructor(jsonData, revision = 0) {
        this.old_data = {};
        this.new_data = {};
        this._log = [];
        this.id = jsonData.id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (dateify(this.timestamp) - dateify(jsonData.timestamp) !== 0) {
            changed = true;
            this.timestamp = jsonData.timestamp ? new Date(jsonData.timestamp) : null;
        }
        if (!isEqual(this.old_data, jsonData.old_data)) {
            changed = true;
            this.old_data = { ...jsonData.old_data };
        }
        if (!isEqual(this.new_data, jsonData.new_data)) {
            changed = true;
            this.new_data = { ...jsonData.new_data };
        }
        if (this.table_name !== jsonData.table_name) {
            changed = true;
            this.table_name = jsonData.table_name;
        }
        if (this.row_id !== jsonData.row_id) {
            changed = true;
            this.row_id = jsonData.row_id;
        }
        if (this.type !== jsonData.type) {
            changed = true;
            this.type = jsonData.type;
        }
        return changed;
    }
}
export const AuditLog_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "timestamp",
        type: "datetime",
    },
    {
        name: "old_data",
        type: "dict",
    },
    {
        name: "new_data",
        type: "dict",
    },
    {
        name: "table_name",
        type: "str",
    },
    {
        name: "row_id",
        type: "int",
    },
    {
        name: "type",
        type: "LogType",
    },
];
tables["AuditLog"] = AuditLog_columns;
export class _Id {
    constructor(jsonData, revision = 0) {
        this._log = [];
        this.id = jsonData.id;
        this.type = jsonData.type;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        return changed;
    }
}
export const Id_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "type",
        type: "str",
        immutable: true,
    },
];
tables["Id"] = Id_columns;
export class _Account {
    constructor(jsonData, revision = 0) {
        this._log = [];
        this.id = jsonData.id;
        this.user_id = jsonData.user_id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.provider_name !== jsonData.provider_name) {
            changed = true;
            this.provider_name = jsonData.provider_name;
        }
        if (this.external_id !== jsonData.external_id) {
            changed = true;
            this.external_id = jsonData.external_id;
        }
        if (this.username !== jsonData.username) {
            changed = true;
            this.username = jsonData.username;
        }
        if (dateify(this.expiry_date) - dateify(jsonData.expiry_date) !== 0) {
            changed = true;
            this.expiry_date = jsonData.expiry_date ? new Date(jsonData.expiry_date) : null;
        }
        if (this.email !== jsonData.email) {
            changed = true;
            this.email = jsonData.email;
        }
        if (this.fullname !== jsonData.fullname) {
            changed = true;
            this.fullname = jsonData.fullname;
        }
        if (this.note !== jsonData.note) {
            changed = true;
            this.note = jsonData.note;
        }
        if (this.email_verified !== jsonData.email_verified) {
            changed = true;
            this.email_verified = jsonData.email_verified;
        }
        if (dateify(this.last_login) - dateify(jsonData.last_login) !== 0) {
            changed = true;
            this.last_login = jsonData.last_login ? new Date(jsonData.last_login) : null;
        }
        if (this.avatar_url !== jsonData.avatar_url) {
            changed = true;
            this.avatar_url = jsonData.avatar_url;
        }
        return changed;
    }
}
export const Account_columns = [
    {
        name: "id",
        type: "int",
        doc: "Internal account id",
        view: { "course_user": false },
    },
    {
        name: "provider_name",
        type: "str",
        doc: "Account provider",
        view: { "course_user": false },
    },
    {
        name: "user_id",
        type: "int",
        doc: "User this account is associated with",
        view: { "course_user": false },
        immutable: true,
    },
    {
        name: "external_id",
        type: "int",
        doc: "Provider's numeric user id (if any)",
    },
    {
        name: "username",
        type: "str",
        doc: "Provider's username for this account",
    },
    {
        name: "expiry_date",
        type: "datetime",
        view: { "course_user": false },
    },
    {
        name: "email",
        type: "str",
        view: { "course_user": false },
    },
    {
        name: "fullname",
        type: "str",
        view: { "course_user": false },
    },
    {
        name: "note",
        type: "str",
        view: { "course_user": false },
    },
    {
        name: "email_verified",
        type: "bool",
    },
    {
        name: "last_login",
        type: "datetime",
        view: { "course_user": false },
    },
    {
        name: "avatar_url",
        type: "str",
        view: { "course_user": false },
    },
];
tables["Account"] = Account_columns;
export class _Course {
    constructor(jsonData, revision = 0) {
        this.json_data = {};
        this._log = [];
        this.id = jsonData.id;
        this.external_id = jsonData.external_id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.name !== jsonData.name) {
            changed = true;
            this.name = jsonData.name;
        }
        if (this.slug !== jsonData.slug) {
            changed = true;
            this.slug = jsonData.slug;
        }
        if (dateify(this.expiry_date) - dateify(jsonData.expiry_date) !== 0) {
            changed = true;
            this.expiry_date = jsonData.expiry_date ? new Date(jsonData.expiry_date) : null;
        }
        if (!isEqual(this.json_data, jsonData.json_data)) {
            changed = true;
            this.json_data = { ...jsonData.json_data };
        }
        return changed;
    }
}
export const Course_columns = [
    {
        name: "id",
        type: "int",
        view: { "course_user": false },
    },
    {
        name: "external_id",
        type: "int",
        view: { "course_user": "course_canvas_id" },
        immutable: true,
        form: "canvas_course",
    },
    {
        name: "name",
        type: "str",
        view: { "course_user": "course_name" },
    },
    {
        name: "slug",
        type: "str",
        view: { "course_user": "course_slug" },
        form: { "slugify": "name" },
    },
    {
        name: "expiry_date",
        type: "datetime",
        view: { "course_user": false },
    },
    {
        name: "json_data",
        type: "dict",
        view: { "course_user": false, "full_user": false },
        _ext_mutable_listener_applied: true,
    },
];
tables["Course"] = Course_columns;
export class _Group {
    constructor(jsonData, revision = 0) {
        this.json_data = {};
        this._log = [];
        this.id = jsonData.id;
        this.course_id = jsonData.course_id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.kind !== jsonData.kind) {
            changed = true;
            this.kind = jsonData.kind;
        }
        if (this.parent_id !== jsonData.parent_id) {
            changed = true;
            this.parent_id = jsonData.parent_id;
        }
        if (this.external_id !== jsonData.external_id) {
            changed = true;
            this.external_id = jsonData.external_id;
        }
        if (this.name !== jsonData.name) {
            changed = true;
            this.name = jsonData.name;
        }
        if (this.slug !== jsonData.slug) {
            changed = true;
            this.slug = jsonData.slug;
        }
        if (this.join_model !== jsonData.join_model) {
            changed = true;
            this.join_model = jsonData.join_model;
        }
        if (this.join_source !== jsonData.join_source) {
            changed = true;
            this.join_source = jsonData.join_source;
        }
        if (!isEqual(this.json_data, jsonData.json_data)) {
            changed = true;
            this.json_data = { ...jsonData.json_data };
        }
        return changed;
    }
}
export const Group_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "kind",
        type: "str",
        form: { "select": "group_kind" },
    },
    {
        name: "course_id",
        type: "int",
        immutable: true,
    },
    {
        name: "parent_id",
        type: "int",
    },
    {
        name: "external_id",
        type: "str",
    },
    {
        name: "name",
        type: "str",
        access: { "write": "member" },
    },
    {
        name: "slug",
        type: "group.slug",
        form: { "slugify": "name" },
    },
    {
        name: "join_model",
        type: "JoinModel",
    },
    {
        name: "join_source",
        type: "str",
        doc: "E.g. gitlab(project_id)",
    },
    {
        name: "json_data",
        type: "dict",
        _ext_mutable_listener_applied: true,
    },
];
tables["Group"] = Group_columns;
export class _Membership {
    constructor(jsonData, revision = 0) {
        this._log = [];
        this.id = jsonData.id;
        this.user_id = jsonData.user_id;
        this.group_id = jsonData.group_id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.role !== jsonData.role) {
            changed = true;
            this.role = jsonData.role;
        }
        if (this.join_model !== jsonData.join_model) {
            changed = true;
            this.join_model = jsonData.join_model;
        }
        return changed;
    }
}
export const Membership_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "user_id",
        type: "int",
        immutable: true,
        access: { "read": "peer" },
    },
    {
        name: "group_id",
        type: "int",
        immutable: true,
    },
    {
        name: "role",
        type: "str",
        icons: { "student": "\ud83e\uddd1\u200d\ud83c\udf93", "ta": "\ud83e\uddd1\u200d\ud83d\udcbb", "teacher": "\ud83e\uddd1\u200d\ud83c\udfeb", "admin": "\ud83e\uddd1\u200d\ud83d\udcbc", "reviewer": "\ud83d\udd75\ufe0f", "": "\ud83e\udd37" },
    },
    {
        name: "join_model",
        type: "JoinModel",
    },
];
tables["Membership"] = Membership_columns;
export class _Enrollment {
    constructor(jsonData, revision = 0) {
        this._log = [];
        this.id = jsonData.id;
        this.user_id = jsonData.user_id;
        this.course_id = jsonData.course_id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.role !== jsonData.role) {
            changed = true;
            this.role = jsonData.role;
        }
        if (this.join_model !== jsonData.join_model) {
            changed = true;
            this.join_model = jsonData.join_model;
        }
        return changed;
    }
}
export const Enrollment_columns = [
    {
        name: "id",
        type: "int",
        view: { "course_user": false },
    },
    {
        name: "user_id",
        type: "int",
        view: { "course_user": false },
        immutable: true,
        access: { "read": "peer" },
    },
    {
        name: "course_id",
        type: "int",
        immutable: true,
    },
    {
        name: "role",
        type: "str",
        icons: { "student": "\ud83e\uddd1\u200d\ud83c\udf93", "ta": "\ud83e\uddd1\u200d\ud83d\udcbb", "teacher": "\ud83e\uddd1\u200d\ud83c\udfeb", "admin": "\ud83e\uddd1\u200d\ud83d\udcbc", "reviewer": "\ud83d\udd75\ufe0f", "": "\ud83e\udd37" },
        access: { "write": "admin", "read": "peer" },
    },
    {
        name: "join_model",
        type: "JoinModel",
    },
];
tables["Enrollment"] = Enrollment_columns;
export class _User {
    constructor(jsonData, revision = 0) {
        this._log = [];
        this.id = jsonData.id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.key !== jsonData.key) {
            changed = true;
            this.key = jsonData.key;
        }
        if (this.lastname !== jsonData.lastname) {
            changed = true;
            this.lastname = jsonData.lastname;
        }
        if (this.firstname !== jsonData.firstname) {
            changed = true;
            this.firstname = jsonData.firstname;
        }
        if (this.email !== jsonData.email) {
            changed = true;
            this.email = jsonData.email;
        }
        if (this.is_admin !== jsonData.is_admin) {
            changed = true;
            this.is_admin = jsonData.is_admin;
        }
        if (this.locale !== jsonData.locale) {
            changed = true;
            this.locale = jsonData.locale;
        }
        if (dateify(this.expiry_date) - dateify(jsonData.expiry_date) !== 0) {
            changed = true;
            this.expiry_date = jsonData.expiry_date ? new Date(jsonData.expiry_date) : null;
        }
        return changed;
    }
}
export const User_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "key",
        type: "str",
        hide: true,
        view: { "course_user": false },
    },
    {
        name: "lastname",
        type: "str",
    },
    {
        name: "firstname",
        type: "str",
    },
    {
        name: "email",
        type: "str",
    },
    {
        name: "is_admin",
        type: "bool",
        hide: true,
        icons: { "true": "\ud83e\uddd1\u200d\ud83d\udcbb", "": " " },
    },
    {
        name: "locale",
        type: "str",
    },
    {
        name: "expiry_date",
        type: "datetime",
        view: { "course_user": false },
    },
];
tables["User"] = User_columns;
export class _Assignment {
    constructor(jsonData, revision = 0) {
        this.json_data = {};
        this._log = [];
        this.id = jsonData.id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.name !== jsonData.name) {
            changed = true;
            this.name = jsonData.name;
        }
        if (this.slug !== jsonData.slug) {
            changed = true;
            this.slug = jsonData.slug;
        }
        if (this.description !== jsonData.description) {
            changed = true;
            this.description = jsonData.description;
        }
        if (this.category !== jsonData.category) {
            changed = true;
            this.category = jsonData.category;
        }
        if (this.course_id !== jsonData.course_id) {
            changed = true;
            this.course_id = jsonData.course_id;
        }
        if (this.assignment_model !== jsonData.assignment_model) {
            changed = true;
            this.assignment_model = jsonData.assignment_model;
        }
        if (this.gitlab_path !== jsonData.gitlab_path) {
            changed = true;
            this.gitlab_path = jsonData.gitlab_path;
        }
        if (this.gitlab_root_path !== jsonData.gitlab_root_path) {
            changed = true;
            this.gitlab_root_path = jsonData.gitlab_root_path;
        }
        if (this.gitlab_test_path !== jsonData.gitlab_test_path) {
            changed = true;
            this.gitlab_test_path = jsonData.gitlab_test_path;
        }
        if (this.canvas_id !== jsonData.canvas_id) {
            changed = true;
            this.canvas_id = jsonData.canvas_id;
        }
        if (dateify(this.release_date) - dateify(jsonData.release_date) !== 0) {
            changed = true;
            this.release_date = jsonData.release_date ? new Date(jsonData.release_date) : null;
        }
        if (dateify(this.due_date) - dateify(jsonData.due_date) !== 0) {
            changed = true;
            this.due_date = jsonData.due_date ? new Date(jsonData.due_date) : null;
        }
        if (dateify(this.grade_by_date) - dateify(jsonData.grade_by_date) !== 0) {
            changed = true;
            this.grade_by_date = jsonData.grade_by_date ? new Date(jsonData.grade_by_date) : null;
        }
        if (!isEqual(this.json_data, jsonData.json_data)) {
            changed = true;
            this.json_data = { ...jsonData.json_data };
        }
        return changed;
    }
}
export const Assignment_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "name",
        type: "str",
        form: true,
    },
    {
        name: "slug",
        type: "str",
        form: { "slugify": "name" },
    },
    {
        name: "description",
        type: "str",
        form: "textarea",
    },
    {
        name: "category",
        type: "str",
        form: { "select": "category" },
    },
    {
        name: "course_id",
        type: "int",
    },
    {
        name: "assignment_model",
        type: "AssignmentModel",
        form: { "select": "assignment_model" },
    },
    {
        name: "gitlab_path",
        type: "str",
        doc: "GitLab source project (with solution / all tests)",
        form: "gitlab:project",
    },
    {
        name: "gitlab_root_path",
        type: "str",
        doc: "GitLab project to be forked to students",
        form: "gitlab:root_project",
    },
    {
        name: "gitlab_test_path",
        type: "str",
        doc: "GitLab project with extra (non-student visible) tests",
        form: "gitlab:test_project",
    },
    {
        name: "canvas_id",
        type: "str",
        doc: "Corresponding assignment in Canvas",
        form: "canvas:assignment",
    },
    {
        name: "release_date",
        type: "datetime",
        doc: "When the assignment should be published",
        form: { "default": "datetime.now()" },
    },
    {
        name: "due_date",
        type: "datetime",
        doc: "Default due date",
        form: true,
    },
    {
        name: "grade_by_date",
        type: "datetime",
        doc: "Date when grading is due",
        form: true,
    },
    {
        name: "json_data",
        type: "dict",
        _ext_mutable_listener_applied: true,
    },
];
tables["Assignment"] = Assignment_columns;
export class _Grader {
    constructor(jsonData, revision = 0) {
        this._log = [];
        this.id = jsonData.id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.grader_id !== jsonData.grader_id) {
            changed = true;
            this.grader_id = jsonData.grader_id;
        }
        if (this.student_assignment_id !== jsonData.student_assignment_id) {
            changed = true;
            this.student_assignment_id = jsonData.student_assignment_id;
        }
        if (dateify(this.grade_by_date) - dateify(jsonData.grade_by_date) !== 0) {
            changed = true;
            this.grade_by_date = jsonData.grade_by_date ? new Date(jsonData.grade_by_date) : null;
        }
        if (dateify(this.graded_date) - dateify(jsonData.graded_date) !== 0) {
            changed = true;
            this.graded_date = jsonData.graded_date ? new Date(jsonData.graded_date) : null;
        }
        if (this.grade_points !== jsonData.grade_points) {
            changed = true;
            this.grade_points = jsonData.grade_points;
        }
        if (this.grade_report !== jsonData.grade_report) {
            changed = true;
            this.grade_report = jsonData.grade_report;
        }
        return changed;
    }
}
export const Grader_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "grader_id",
        type: "int",
    },
    {
        name: "student_assignment_id",
        type: "int",
    },
    {
        name: "grade_by_date",
        type: "datetime",
    },
    {
        name: "graded_date",
        type: "datetime",
    },
    {
        name: "grade_points",
        type: "int",
    },
    {
        name: "grade_report",
        type: "str",
    },
];
tables["Grader"] = Grader_columns;
export class _StudentAssignment {
    constructor(jsonData, revision = 0) {
        this.json_data = {};
        this._log = [];
        this.id = jsonData.id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.user_id !== jsonData.user_id) {
            changed = true;
            this.user_id = jsonData.user_id;
        }
        if (this.assignment_id !== jsonData.assignment_id) {
            changed = true;
            this.assignment_id = jsonData.assignment_id;
        }
        if (this.project_id !== jsonData.project_id) {
            changed = true;
            this.project_id = jsonData.project_id;
        }
        if (this.canvas_id !== jsonData.canvas_id) {
            changed = true;
            this.canvas_id = jsonData.canvas_id;
        }
        if (dateify(this.due_date) - dateify(jsonData.due_date) !== 0) {
            changed = true;
            this.due_date = jsonData.due_date ? new Date(jsonData.due_date) : null;
        }
        if (!isEqual(this.json_data, jsonData.json_data)) {
            changed = true;
            this.json_data = { ...jsonData.json_data };
        }
        return changed;
    }
}
export const StudentAssignment_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "user_id",
        type: "int",
    },
    {
        name: "assignment_id",
        type: "int",
    },
    {
        name: "project_id",
        type: "int",
    },
    {
        name: "canvas_id",
        type: "str",
    },
    {
        name: "due_date",
        type: "datetime",
    },
    {
        name: "json_data",
        type: "dict",
        _ext_mutable_listener_applied: true,
    },
];
tables["StudentAssignment"] = StudentAssignment_columns;
export class _Project {
    constructor(jsonData, revision = 0) {
        this.json_data = {};
        this._log = [];
        this.id = jsonData.id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.name !== jsonData.name) {
            changed = true;
            this.name = jsonData.name;
        }
        if (this.slug !== jsonData.slug) {
            changed = true;
            this.slug = jsonData.slug;
        }
        if (this.namespace_slug !== jsonData.namespace_slug) {
            changed = true;
            this.namespace_slug = jsonData.namespace_slug;
        }
        if (this.description !== jsonData.description) {
            changed = true;
            this.description = jsonData.description;
        }
        if (this.course_id !== jsonData.course_id) {
            changed = true;
            this.course_id = jsonData.course_id;
        }
        if (this.owner_id !== jsonData.owner_id) {
            changed = true;
            this.owner_id = jsonData.owner_id;
        }
        if (this.owner_kind !== jsonData.owner_kind) {
            changed = true;
            this.owner_kind = jsonData.owner_kind;
        }
        if (this.gitlab_id !== jsonData.gitlab_id) {
            changed = true;
            this.gitlab_id = jsonData.gitlab_id;
        }
        if (this.submitted_ref !== jsonData.submitted_ref) {
            changed = true;
            this.submitted_ref = jsonData.submitted_ref;
        }
        if (!isEqual(this.json_data, jsonData.json_data)) {
            changed = true;
            this.json_data = { ...jsonData.json_data };
        }
        return changed;
    }
}
export const Project_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "name",
        type: "str",
        form: true,
        sync: true,
    },
    {
        name: "slug",
        type: "str",
        form: { "slugify": "name" },
        sync: "immutable",
    },
    {
        name: "namespace_slug",
        type: "str",
        form: false,
        sync: "immutable",
    },
    {
        name: "description",
        type: "str",
        form: "textarea",
        sync: true,
    },
    {
        name: "course_id",
        type: "int",
    },
    {
        name: "owner_id",
        type: "int",
        doc: "A user, group or course id",
    },
    {
        name: "owner_kind",
        type: "str",
    },
    {
        name: "gitlab_id",
        type: "str",
    },
    {
        name: "submitted_ref",
        type: "str",
        doc: "Identifies actual submission (a tag, branch or commit id)",
    },
    {
        name: "json_data",
        type: "dict",
        _ext_mutable_listener_applied: true,
    },
];
tables["Project"] = Project_columns;
export class _ProjectTestRun {
    constructor(jsonData, revision = 0) {
        this._log = [];
        this.id = jsonData.id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.project_id !== jsonData.project_id) {
            changed = true;
            this.project_id = jsonData.project_id;
        }
        if (dateify(this.timestamp) - dateify(jsonData.timestamp) !== 0) {
            changed = true;
            this.timestamp = jsonData.timestamp ? new Date(jsonData.timestamp) : null;
        }
        if (this.compile_passed !== jsonData.compile_passed) {
            changed = true;
            this.compile_passed = jsonData.compile_passed;
        }
        if (this.test_passed !== jsonData.test_passed) {
            changed = true;
            this.test_passed = jsonData.test_passed;
        }
        if (this.result_points !== jsonData.result_points) {
            changed = true;
            this.result_points = jsonData.result_points;
        }
        if (this.result_text !== jsonData.result_text) {
            changed = true;
            this.result_text = jsonData.result_text;
        }
        if (this.result_url !== jsonData.result_url) {
            changed = true;
            this.result_url = jsonData.result_url;
        }
        return changed;
    }
}
export const ProjectTestRun_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "project_id",
        type: "int",
    },
    {
        name: "timestamp",
        type: "datetime",
    },
    {
        name: "compile_passed",
        type: "bool",
    },
    {
        name: "test_passed",
        type: "bool",
    },
    {
        name: "result_points",
        type: "int",
    },
    {
        name: "result_text",
        type: "str",
    },
    {
        name: "result_url",
        type: "str",
    },
];
tables["ProjectTestRun"] = ProjectTestRun_columns;
export class _LastSync {
    constructor(jsonData, revision = 0) {
        this._log = [];
        this.id = jsonData.id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.obj_id !== jsonData.obj_id) {
            changed = true;
            this.obj_id = jsonData.obj_id;
        }
        if (this.obj_type !== jsonData.obj_type) {
            changed = true;
            this.obj_type = jsonData.obj_type;
        }
        if (dateify(this.sync_incoming) - dateify(jsonData.sync_incoming) !== 0) {
            changed = true;
            this.sync_incoming = jsonData.sync_incoming ? new Date(jsonData.sync_incoming) : null;
        }
        if (dateify(this.sync_outgoing) - dateify(jsonData.sync_outgoing) !== 0) {
            changed = true;
            this.sync_outgoing = jsonData.sync_outgoing ? new Date(jsonData.sync_outgoing) : null;
        }
        return changed;
    }
}
export const LastSync_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "obj_id",
        type: "int",
    },
    {
        name: "obj_type",
        type: "str",
    },
    {
        name: "sync_incoming",
        type: "datetime",
    },
    {
        name: "sync_outgoing",
        type: "datetime",
    },
];
tables["LastSync"] = LastSync_columns;
export class _Oauth1token {
    constructor(jsonData, revision = 0) {
        this._log = [];
        this.id = jsonData.id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.provider_name !== jsonData.provider_name) {
            changed = true;
            this.provider_name = jsonData.provider_name;
        }
        if (this.oauth_token !== jsonData.oauth_token) {
            changed = true;
            this.oauth_token = jsonData.oauth_token;
        }
        if (this.oauth_token_secret !== jsonData.oauth_token_secret) {
            changed = true;
            this.oauth_token_secret = jsonData.oauth_token_secret;
        }
        if (this.user_id !== jsonData.user_id) {
            changed = true;
            this.user_id = jsonData.user_id;
        }
        return changed;
    }
}
export const Oauth1token_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "provider_name",
        type: "str",
    },
    {
        name: "oauth_token",
        type: "str",
    },
    {
        name: "oauth_token_secret",
        type: "str",
    },
    {
        name: "user_id",
        type: "int",
    },
];
tables["Oauth1token"] = Oauth1token_columns;
export class _Oauth2token {
    constructor(jsonData, revision = 0) {
        this._log = [];
        this.id = jsonData.id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.provider_name !== jsonData.provider_name) {
            changed = true;
            this.provider_name = jsonData.provider_name;
        }
        if (this.token_type !== jsonData.token_type) {
            changed = true;
            this.token_type = jsonData.token_type;
        }
        if (this.access_token !== jsonData.access_token) {
            changed = true;
            this.access_token = jsonData.access_token;
        }
        if (this.refresh_token !== jsonData.refresh_token) {
            changed = true;
            this.refresh_token = jsonData.refresh_token;
        }
        if (dateify(this.expires_at) - dateify(jsonData.expires_at) !== 0) {
            changed = true;
            this.expires_at = jsonData.expires_at ? new Date(jsonData.expires_at) : null;
        }
        if (this.user_id !== jsonData.user_id) {
            changed = true;
            this.user_id = jsonData.user_id;
        }
        return changed;
    }
}
export const Oauth2token_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "provider_name",
        type: "str",
    },
    {
        name: "token_type",
        type: "str",
    },
    {
        name: "access_token",
        type: "str",
    },
    {
        name: "refresh_token",
        type: "str",
    },
    {
        name: "expires_at",
        type: "datetime",
    },
    {
        name: "user_id",
        type: "int",
    },
];
tables["Oauth2token"] = Oauth2token_columns;
export class _CourseUser {
    constructor(jsonData, revision = 0) {
        this._log = [];
        this.id = jsonData.id;
        this.course_id = jsonData.course_id;
        this.course_canvas_id = jsonData.course_canvas_id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.lastname !== jsonData.lastname) {
            changed = true;
            this.lastname = jsonData.lastname;
        }
        if (this.firstname !== jsonData.firstname) {
            changed = true;
            this.firstname = jsonData.firstname;
        }
        if (this.email !== jsonData.email) {
            changed = true;
            this.email = jsonData.email;
        }
        if (this.is_admin !== jsonData.is_admin) {
            changed = true;
            this.is_admin = jsonData.is_admin;
        }
        if (this.locale !== jsonData.locale) {
            changed = true;
            this.locale = jsonData.locale;
        }
        if (this.role !== jsonData.role) {
            changed = true;
            this.role = jsonData.role;
        }
        if (this.join_model !== jsonData.join_model) {
            changed = true;
            this.join_model = jsonData.join_model;
        }
        if (this.course_name !== jsonData.course_name) {
            changed = true;
            this.course_name = jsonData.course_name;
        }
        if (this.course_slug !== jsonData.course_slug) {
            changed = true;
            this.course_slug = jsonData.course_slug;
        }
        return changed;
    }
}
export const CourseUser_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "lastname",
        type: "str",
    },
    {
        name: "firstname",
        type: "str",
    },
    {
        name: "email",
        type: "str",
    },
    {
        name: "is_admin",
        type: "bool",
        hide: true,
        icons: { "true": "\ud83e\uddd1\u200d\ud83d\udcbb", "": " " },
    },
    {
        name: "locale",
        type: "str",
    },
    {
        name: "course_id",
        type: "int",
        immutable: true,
    },
    {
        name: "role",
        type: "str",
        icons: { "student": "\ud83e\uddd1\u200d\ud83c\udf93", "ta": "\ud83e\uddd1\u200d\ud83d\udcbb", "teacher": "\ud83e\uddd1\u200d\ud83c\udfeb", "admin": "\ud83e\uddd1\u200d\ud83d\udcbc", "reviewer": "\ud83d\udd75\ufe0f", "": "\ud83e\udd37" },
        access: { "write": "admin", "read": "peer" },
    },
    {
        name: "join_model",
        type: "JoinModel",
    },
    {
        name: "course_canvas_id",
        type: "int",
        view: { "course_user": "course_canvas_id" },
        immutable: true,
        form: "canvas_course",
    },
    {
        name: "course_name",
        type: "str",
        view: { "course_user": "course_name" },
    },
    {
        name: "course_slug",
        type: "str",
        view: { "course_user": "course_slug" },
        form: { "slugify": "name" },
    },
];
tables["CourseUser"] = CourseUser_columns;
export class _UserAccount {
    constructor(jsonData, revision = 0) {
        this._log = [];
        this.id = jsonData.id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.canvas_id !== jsonData.canvas_id) {
            changed = true;
            this.canvas_id = jsonData.canvas_id;
        }
        if (this.canvas_username !== jsonData.canvas_username) {
            changed = true;
            this.canvas_username = jsonData.canvas_username;
        }
        if (this.gitlab_id !== jsonData.gitlab_id) {
            changed = true;
            this.gitlab_id = jsonData.gitlab_id;
        }
        if (this.gitlab_username !== jsonData.gitlab_username) {
            changed = true;
            this.gitlab_username = jsonData.gitlab_username;
        }
        if (this.discord_id !== jsonData.discord_id) {
            changed = true;
            this.discord_id = jsonData.discord_id;
        }
        if (this.discord_username !== jsonData.discord_username) {
            changed = true;
            this.discord_username = jsonData.discord_username;
        }
        return changed;
    }
}
export const UserAccount_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "canvas_id",
        type: "int",
    },
    {
        name: "canvas_username",
        type: "str",
    },
    {
        name: "gitlab_id",
        type: "int",
    },
    {
        name: "gitlab_username",
        type: "str",
    },
    {
        name: "discord_id",
        type: "int",
    },
    {
        name: "discord_username",
        type: "str",
    },
];
tables["UserAccount"] = UserAccount_columns;
export class _FullUser {
    constructor(jsonData, revision = 0) {
        this._log = [];
        this.id = jsonData.id;
        this.course_id = jsonData.course_id;
        this.course_canvas_id = jsonData.course_canvas_id;
        this.update(jsonData, revision);
    }
    log(level, msg) {
        if (msg !== undefined)
            this._log.unshift([level, msg]);
        else
            this._log.unshift(['info', level]);
    }
    clear_log() {
        this._log = [];
    }
    update(jsonData, revision = 0) {
        if (this.id !== jsonData.id)
            throw new Error("Data doesn't match ID");
        this.revision = revision;
        let changed = false;
        if (this.lastname !== jsonData.lastname) {
            changed = true;
            this.lastname = jsonData.lastname;
        }
        if (this.firstname !== jsonData.firstname) {
            changed = true;
            this.firstname = jsonData.firstname;
        }
        if (this.email !== jsonData.email) {
            changed = true;
            this.email = jsonData.email;
        }
        if (this.is_admin !== jsonData.is_admin) {
            changed = true;
            this.is_admin = jsonData.is_admin;
        }
        if (this.locale !== jsonData.locale) {
            changed = true;
            this.locale = jsonData.locale;
        }
        if (this.role !== jsonData.role) {
            changed = true;
            this.role = jsonData.role;
        }
        if (this.join_model !== jsonData.join_model) {
            changed = true;
            this.join_model = jsonData.join_model;
        }
        if (this.course_name !== jsonData.course_name) {
            changed = true;
            this.course_name = jsonData.course_name;
        }
        if (this.course_slug !== jsonData.course_slug) {
            changed = true;
            this.course_slug = jsonData.course_slug;
        }
        if (this.canvas_id !== jsonData.canvas_id) {
            changed = true;
            this.canvas_id = jsonData.canvas_id;
        }
        if (this.canvas_username !== jsonData.canvas_username) {
            changed = true;
            this.canvas_username = jsonData.canvas_username;
        }
        if (this.gitlab_id !== jsonData.gitlab_id) {
            changed = true;
            this.gitlab_id = jsonData.gitlab_id;
        }
        if (this.gitlab_username !== jsonData.gitlab_username) {
            changed = true;
            this.gitlab_username = jsonData.gitlab_username;
        }
        if (this.discord_id !== jsonData.discord_id) {
            changed = true;
            this.discord_id = jsonData.discord_id;
        }
        if (this.discord_username !== jsonData.discord_username) {
            changed = true;
            this.discord_username = jsonData.discord_username;
        }
        return changed;
    }
}
export const FullUser_columns = [
    {
        name: "id",
        type: "int",
    },
    {
        name: "lastname",
        type: "str",
    },
    {
        name: "firstname",
        type: "str",
    },
    {
        name: "email",
        type: "str",
    },
    {
        name: "is_admin",
        type: "bool",
        hide: true,
        icons: { "true": "\ud83e\uddd1\u200d\ud83d\udcbb", "": " " },
    },
    {
        name: "locale",
        type: "str",
    },
    {
        name: "course_id",
        type: "int",
        immutable: true,
    },
    {
        name: "role",
        type: "str",
        icons: { "student": "\ud83e\uddd1\u200d\ud83c\udf93", "ta": "\ud83e\uddd1\u200d\ud83d\udcbb", "teacher": "\ud83e\uddd1\u200d\ud83c\udfeb", "admin": "\ud83e\uddd1\u200d\ud83d\udcbc", "reviewer": "\ud83d\udd75\ufe0f", "": "\ud83e\udd37" },
        access: { "write": "admin", "read": "peer" },
    },
    {
        name: "join_model",
        type: "JoinModel",
    },
    {
        name: "course_canvas_id",
        type: "int",
        view: { "course_user": "course_canvas_id" },
        immutable: true,
        form: "canvas_course",
    },
    {
        name: "course_name",
        type: "str",
        view: { "course_user": "course_name" },
    },
    {
        name: "course_slug",
        type: "str",
        view: { "course_user": "course_slug" },
        form: { "slugify": "name" },
    },
    {
        name: "canvas_id",
        type: "int",
    },
    {
        name: "canvas_username",
        type: "str",
    },
    {
        name: "gitlab_id",
        type: "int",
    },
    {
        name: "gitlab_username",
        type: "str",
    },
    {
        name: "discord_id",
        type: "int",
    },
    {
        name: "discord_username",
        type: "str",
    },
];
tables["FullUser"] = FullUser_columns;
export const PRIVILEGED_ROLES = ['ta', 'teacher', 'admin'];
export const LogType = { 'UPDATE': 'UPDATE', 'INSERT': 'INSERT', 'DELETE': 'DELETE' };
export const LogType_NAMES = ['UPDATE', 'INSERT', 'DELETE'];
console.log(LogType, LogType_NAMES);
export const JoinModel = { 'RESTRICTED': 'Restricted – members of the parent group can join freely', 'OPEN': 'Open – users can join/leave freely', 'AUTO': 'Auto – users are automatically added to this group', 'CLOSED': 'Closed – group owner must add users', 'REMOVED': 'Removed – user was manually removed from group' };
export const JoinModel_NAMES = ['RESTRICTED', 'OPEN', 'AUTO', 'CLOSED', 'REMOVED'];
console.log(JoinModel, JoinModel_NAMES);
export const AssignmentModel = { 'GITLAB_STUDENT_FORK': 'Gitlab project forked to each student', 'GITLAB_GROUP_FORK': 'Gitlab project forked to each student group', 'GITLAB_GROUP_PROJECT': 'Gitlab project created by student group', 'GITLAB_STUDENT_PROJECT': 'Gitlab project created by student' };
export const AssignmentModel_NAMES = ['GITLAB_STUDENT_FORK', 'GITLAB_GROUP_FORK', 'GITLAB_GROUP_PROJECT', 'GITLAB_STUDENT_PROJECT'];
console.log(AssignmentModel, AssignmentModel_NAMES);
